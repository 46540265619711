import { useTranslation } from '@cms/i18n/client';
import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { CuratedNewsItem, FeaturedCarouselLayout } from '@common/clients/api';
import { NewsFunctionality } from '@common/types/NewsFunctionality';
import { Typography } from '@common/types/Typography';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { ProTag } from '@web/atoms/ProTag';
import { TagElement, TagSize } from '@web/atoms/TagElement';
import { DefaultDynamicSizes, WebpImage } from '@web/atoms/WebpImage';

import { FeaturedCarrouselConfigProps, useFeaturedCarouselThemeConfig } from './FeaturedCarrousel.config';
import { ItemConfig, ItemVariant, LayoutConfig } from './FeaturedCarrousel.types';

import styles from './FeaturedCarrousel.module.scss';

export interface Props {
    item: CuratedNewsItem;
    index: number;
    isMobile?: boolean;
    layout: FeaturedCarouselLayout;
}

interface ItemVariantConfig {
    showTitleBackground?: boolean;
    tagSize?: TagSize;
    typography?: Typography;
}

const DEFAULT_LAYOUT_CONFIG: LayoutConfig = {
    [FeaturedCarouselLayout.EXPANDED_FOOTER]: [
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            large: true,
        },
        {
            variant: ItemVariant.FOOTER,
        },
        {
            variant: ItemVariant.FOOTER,
        },
        {
            variant: ItemVariant.FOOTER,
        },
    ],
    [FeaturedCarouselLayout.EXPANDED_SIDE]: [
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            imageSizes: DefaultDynamicSizes.TwoThird,
            large: true,
        },
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            imageSizes: DefaultDynamicSizes.OneThird,
        },
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            imageSizes: DefaultDynamicSizes.OneThird,
        },
    ],
    [FeaturedCarouselLayout.MINIFIED_SIDE]: [
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            large: true,
        },
        {
            variant: ItemVariant.MINIFIED,
        },
        {
            variant: ItemVariant.MINIFIED,
        },
        {
            variant: ItemVariant.MINIFIED,
        },
        {
            variant: ItemVariant.MINIFIED,
        },
    ],
    [FeaturedCarouselLayout.SINGLE_MAIN]: [
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            large: true,
        },
    ],
    [FeaturedCarouselLayout.TWIN_MAINS]: [
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            large: true,
        },
        {
            variant: ItemVariant.NO_CAPTION_BOX,
            large: true,
        },
    ],
};

const ITEM_VARIANT_CONFIG: Partial<Record<ItemVariant, ItemVariantConfig>> = {
    [ItemVariant.BOTTOM_CAPTION_BOX]: { tagSize: TagSize.LARGE },
    [ItemVariant.BOTTOM_CAPTION_BOX_INVERTED]: { tagSize: TagSize.LARGE },
    [ItemVariant.FLOATING_CAPTION_BOX_INVERTED]: { showTitleBackground: true },
    [ItemVariant.FOOTER]: { typography: Typography.LINK_LIST },
};

const getItemConfig = (
    themeConfig: FeaturedCarrouselConfigProps,
    layout: FeaturedCarouselLayout,
    index: number,
    isMobile?: boolean,
): ItemConfig | undefined => {
    // Use layout from first item on mobile
    const layoutIndex = isMobile ? 0 : index;
    const defaultItemConfig = DEFAULT_LAYOUT_CONFIG[layout][layoutIndex];
    const themeItemConfig = themeConfig.layoutConfig?.[layout]?.[layoutIndex];
    return defaultItemConfig ? { ...defaultItemConfig, ...themeItemConfig } : undefined;
};

export const FeaturedCarrouselItem = ({ index, item, layout, isMobile }: Props) => {
    const { category, newsSubTitle } = item.newsItem;
    const __components = useTranslation('components').t;
    const themeConfig = useFeaturedCarouselThemeConfig();
    const itemConfig = getItemConfig(themeConfig, layout, index, isMobile);
    const isProArticle = category?.functionality === NewsFunctionality.PREMIUM;

    if (!itemConfig) {
        return null;
    }

    const variantConfig = ITEM_VARIANT_CONFIG[itemConfig.variant];
    const typography = itemConfig.typography || variantConfig?.typography || themeConfig.typography;

    const { newsItem, fontSize } = item;
    const title = item.title || newsItem.newsTitle || '';

    let categoryNameWithoutPro = category?.name || '';
    if (categoryNameWithoutPro.startsWith('PRO')) {
        categoryNameWithoutPro = categoryNameWithoutPro.substring(4).trim();
    }

    const tagContent = newsSubTitle || categoryNameWithoutPro || '';

    const classNames = [styles['item'], styles[`item-variant-${itemConfig.variant}`]];

    if (itemConfig.large) {
        classNames.push(styles['item-size-large']);
    }

    return (
        <li className={classNames.join(' ')} id={`${isMobile ? 'mobile-' : ''}featuredItem${index}`}>
            <article className={styles['item-article']}>
                <Link
                    className={styles['item-link']}
                    href={newsItem.path || `/${newsItem.newsID}/${newsItem.mainTagSlug}`}
                >
                    <figure className={styles['item-image-container']}>
                        <WebpImage
                            defaultSize={WebpWidthEnum.WIDTH_450}
                            src={newsItem?.image || ''}
                            fill={true}
                            sizes={itemConfig.imageSizes || '100vw'}
                            alt=""
                            priority={true}
                        />
                        <div className={styles['pro-tag-container']}>
                            {isProArticle ? <ProTag tag={tagContent} /> : null}
                        </div>
                    </figure>
                    <div className={styles['item-info']}>
                        {themeConfig.showTags || newsSubTitle ? (
                            <TagElement
                                className={styles['item-tag']}
                                allowSubtitle={true}
                                item={newsItem}
                                size={variantConfig?.tagSize || TagSize.SMALL}
                                isNewsBlock
                                uppercase
                            />
                        ) : null}
                        <h2
                            className={`${styles['item-title']} ${
                                styles[`item-title-typography-${typography}`]
                            } ${styles[`item-title-font-size-${fontSize}`]}`}
                        >
                            {variantConfig?.showTitleBackground ? (
                                <span className={styles['item-title-background']} data-content={title}></span>
                            ) : null}
                            {title}
                        </h2>
                        {themeConfig.showCommentCount && newsItem.commentCount ? (
                            <div className={styles['item-comments']}>
                                <Icon.comment />
                                {__components('featuredCarrousel.reactions', {
                                    count: newsItem.commentCount,
                                })}
                            </div>
                        ) : null}
                    </div>
                </Link>
            </article>
        </li>
    );
};
